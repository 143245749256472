import oauthSettings from './vsko-oauth-configuration-stripped.json';

export const settings = {
  //eslint-disable-line
  angularModuleName: "WellBe Project",
  projectName: "wellbe",
  projectTitle: "WellBe",
  projectVersion: "dev",
  projectDescription: "A survey editor",
  googleAnalytics: {
    enabled: false,
    token: "", // GET YOUR OWN!!!.
  },
  apisAndUrls: {
    api: "https://testapi.katholiekonderwijs.vlaanderen",
    myVsko: "https://testmijn.vsko.be/#/me",
    samApi: "https://testapi.katholiekonderwijs.vlaanderen/sam",
  },
  samenscholing: {
    name: "SAM-ACCESSTOKEN-TEST",
    token: "9f775e43-bdb9-48f0-b7a6-04860a1b4aa8",
  },
  logging: {
    logentriesEnabled: false,
    level: "INFO",
    logentriesToken: "", // GET YOUR OWN TOKEN.
    sentry: {
      enabled: false,
      url: "https://945630e2fbbc4b2a96395dffd786c8cb@o268751.ingest.sentry.io/4504316438839296",
      sentryPerformanceSampleRate: 1.0,
      fetchInterceptor: false,
    },
  },
  oauth: oauthSettings,
  loginRequired: true,
  debug: false,
  sendRealEmails: "true",
  adminEmail: "greet.vanhove@katholiekonderwijs.vlaanderen",
  environment: "TEST",
};
